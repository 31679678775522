/* eslint-disable */
import React from 'react'
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import Layout from '../components/layout'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';
import Offers from "../components/offers";

import '../layouts/css/bazaarvoice.css'

// Import paragraphs
import BuyOnline from '../paragraphs/buy_online.js';
import ShopScotties from '../paragraphs/shop_scotties.js';
import FourIconGrid from '../paragraphs/four_icon_grid.js';

import softness from "./../layouts/images/Softnessyoucanfeel.svg";
import tsBox from "./../assets/images/ts-box@2x.png";
import next from "./../layouts/images/right.svg";
import prev from "./../layouts/images/left.svg";
import close from "./../layouts/images/Blue-close.svg";
import Everyday from "./../assets/images/everyday-header.jpg";

import sendEvent from '../helpers/sendAnalytics';

class ProductTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleShowModal = this.handleShowModal.bind(this);
    this.state = {
      variation: 0,
      variations: this.props.data.nodeProduct.relationships.field_product_variation,
      showModal: false,
      size: this.props.data.nodeProduct.relationships.field_product_variation[0].relationships.field_product_sizes.name
    };
    this.loadDynamicScript(
      this.props.data.nodeProduct.relationships.field_bazaarvoice_products,
      this.props.data.nodeProduct
    );
  }
  handleShowModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }
  handleChange(e,id) {
    e.preventDefault();
    let index = _.findIndex(this.state.variations, {id: id});
    this.setState({
      variation: index,
      showModal: true
    });

    sendEvent({
      ga: {
        category: 'Product_Engagement',
        action: 'SoothingAloe_Style_Selector',
        label: 'View_Style_Swatch'
      }
    });
  }
  handleSize(e,size) {
    e.preventDefault();
    this.setState({
      size: size
    });

    sendEvent({
      ga: {
        category: 'Product_Engagement',
        action: 'EverydayComfort_Style_Selector',
        label: 'Count_Option'
      }
    });
  }
  handleNext(e) {
    e.preventDefault();

    let index = this.state.variation;
    let slidesLength = this.state.variations.length;

    if (index === (slidesLength - 1)) {
      index = -1;
    }

    ++index;

    this.setState({
      variation: index
    });
  }
  handlePrev(e) {
    e.preventDefault();

    let index = this.state.variation;
    let slidesLength = this.state.variations.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      variation: index
    });
  }
  loadDynamicScript(products, node) {
    if (typeof window !== 'undefined') {
      const existingScript = document.getElementById('bazaarVoice');

      window.bvDCC = {
        catalogData: {
          locale: 'en_US',
          catalogProducts: []
        }
      };

      let productUpcs = [];
      products.forEach(item => {
        productUpcs.push(item.field_bazaarvoice_upc)
      });

      products.forEach(item => {
        window.bvDCC.catalogData.catalogProducts.push({
          "productId": item.field_bazaarvoice_id,
          "productName": item.title,
          "productDescription": item.body.value,
          "productImageURL": item.field_bazaarvoice_image,
          "productPageURL": "https://www.scottiesfacial.com" + node.path.alias,
          "brandName": "Scotties",
          "categoryPath": [
            {
              "id": node.id,
              "Name": node.title
            }
          ],
          "upcs": productUpcs,
          "family": node.title
        })
      });

      window.bvCallback = function(BV) {
        for (var i=0, len=window.bvDCC.catalogData.catalogProducts.length; i < len; ++i) {
          BV.pixel.trackEvent("CatalogUpdate", {
            type: 'Product',
            locale: window.bvDCC.locale,
            catalogProducts: [ window.bvDCC.catalogData.catalogProducts[i] ]
          });
        }
      }

      if (!existingScript) {
        const script = document.createElement('script');

        // Not sure how to do this yet
        //let env = (process.env.GATSBY_ACTIVE_ENV === 'production') ? 'production' : 'staging';
        let env = 'production';

        script.src = `https://apps.bazaarvoice.com/deployments/scotties/main_site/${env}/en_US/bv.js`;
        script.id = 'bazaarVoice';
        script.async = true;
        document.body.appendChild(script);
      }
    }
  };
  getSizes(variations) {
    let sizes = [];
    variations.forEach(variation => {
      sizes.push(variation.relationships.field_product_sizes.name)
    });

    return _.uniq(sizes);
  }
  scrollToReviews() {
    if (typeof window !== 'undefined') {
      document.getElementById('review-holder').scrollIntoView();
    }
  };
  render() {
    const retailers = this.props.data.allNodeStore.edges
    const data = this.props.data;
    const others = this.props.data.allNodeProduct.edges;
    const sizes = this.getSizes(data.nodeProduct.relationships.field_product_variation);
    const tripleSoft = (data.nodeProduct.id === 'bb5ec40d-3e69-5466-9cca-efc4195fc4fc')
    const soothingaloe = (data.nodeProduct.id === 'f3534e4d-130c-5d93-a64e-f18aca9518ea')

    return (
      <Layout className={tripleSoft ? 'triple-soft-page':'page-wrapper single-product ' + data.nodeProduct.id} >
        <SEO meta={data.nodeProduct.field_meta_tags} />
        <Header retailers={retailers}></Header>
        <div style={{clear:'both'}} />
        <main itemScope itemType="https://schema.org/Product">
          <div className="internal-header-wrapper" id={data.nodeProduct.id} style={{backgroundImage: `url(${data.nodeProduct.relationships.field_image_background_detail.localFile ? data.nodeProduct.relationships.field_image_background_detail.localFile.childImageSharp.resolutions.src:Everyday})`}}>
            { soothingaloe ?  <img className="ts-box" src={tsBox} alt="" />:null}
            <div className="internal-header-text">
              <h1 className="white-sans-title" itemProp="name">{data.nodeProduct.title}{ (tripleSoft) ? <sup>®</sup> : null}{ (soothingaloe) ? <> triple soft<sup>®</sup></> : null}</h1>

              <div
                className="rating_summary desktop"
                data-bv-show="rating_summary"
                data-bv-product-id={data.nodeProduct.relationships.field_bazaarvoice_products[0].field_bazaarvoice_id}
                onClick={this.scrollToReviews}
              />

              <div className="medium-text" dangerouslySetInnerHTML={{__html: data.nodeProduct.field_product_details.value }}></div>


              <div className="box-sizes">
                <div className="bold-label">Box Sizes</div>
                { ((sizes.includes('54ct')) || sizes.includes('64ct') || sizes.includes('66ct') || sizes.includes('92ct'))
                  ? (
                      <div className="box-size">
                        <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="03-Product-Page" transform="translate(-143.000000, -462.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g id="Group-5" transform="translate(143.000000, 462.000000)">
                                        <path d="M1.54825848,8.06268311 L1.54825848,21.9891357 L17.6736247,21.9891357 L17.6736247,8.06268311 L1.54825848,8.06268311 Z M1.0823161,6.59674072 L18.1395671,6.59674072 C18.6918518,6.59674072 19.1395671,7.04445597 19.1395671,7.59674072 L19.1395671,22.4550781 C19.1395671,23.0073629 18.6918518,23.4550781 18.1395671,23.4550781 L1.0823161,23.4550781 C0.530031351,23.4550781 0.0823161006,23.0073629 0.0823161006,22.4550781 L0.0823161006,7.59674072 C0.0823161006,7.04445597 0.530031351,6.59674072 1.0823161,6.59674072 Z" id="Rectangle-2-Copy-8"></path>
                                        <path d="M13.2515765,6.6054197 L14.5786996,1.70056372 C12.8601908,1.34047574 11.2824473,1.39584676 9.83544672,1.85995467 C8.25017291,2.36841203 6.54702637,2.45792099 4.73421467,2.13398643 L5.85713713,6.6054197 L13.2515765,6.6054197 Z M4.56611239,7.50686257 L3.03499243,1.33266865 C2.90555942,0.792054938 3.4014943,0.309759245 3.93828927,0.454214246 C5.93311595,0.991035013 7.74470505,0.991035013 9.38772883,0.464055047 C11.3116988,-0.153034995 13.4092894,-0.153034995 15.6658283,0.454214246 C16.0541266,0.558707834 16.2857402,0.956527681 16.1849123,1.34579396 L14.5284373,7.51998788 C14.4446824,7.84334039 14.1529058,8.06916997 13.8188822,8.06916997 L5.2789382,8.06916997 C4.939871,8.06916997 4.64506023,7.83661067 4.56611239,7.50686257 Z" id="Path-3-Copy-8"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="intro-bold">
                          { (sizes.includes('54ct')) ? (<span>54ct</span>) : null }
                          { (sizes.includes('64ct')) ? (<span>64ct</span>) : null }
                          { (sizes.includes('66ct')) ? (<span>66ct</span>) : null }
                          { (sizes.includes('92ct')) ? (<span>92ct</span>) : null }
                        </div>
                      </div>
                    )
                : null }
                { ((sizes.includes('110ct')) || sizes.includes('185ct'))
                  ? (
                      <div className="box-size">
                        <svg width="35px" height="24px" viewBox="0 0 35 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="03-Product-Page" transform="translate(-136.000000, -510.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g id="Group-6" transform="translate(136.000000, 510.000000)">
                                        <path d="M1.9831491,8.25 L1.9831491,22.5 L33.2597448,22.5 L33.2597448,8.25 L1.9831491,8.25 Z M1.4937874,6.75 L33.7491065,6.75 C34.3013913,6.75 34.7491065,7.19771525 34.7491065,7.75 L34.7491065,23 C34.7491065,23.5522847 34.3013913,24 33.7491065,24 L1.4937874,24 C0.94150265,24 0.4937874,23.5522847 0.4937874,23 L0.4937874,7.75 C0.4937874,7.19771525 0.94150265,6.75 1.4937874,6.75 Z" id="Rectangle-2-Copy-9"></path>
                                        <path d="M21.3202432,6.75888062 L22.6685679,1.7400722 C20.9226049,1.37161845 19.319656,1.42827587 17.8495387,1.90316621 C16.2389392,2.42343634 14.5085839,2.51502483 12.6668114,2.18356443 L13.8076732,6.75888062 L21.3202432,6.75888062 Z M12.4960236,7.68126633 L10.940443,1.36362998 C10.8089423,0.810456415 11.3128,0.31695575 11.8581706,0.464766813 C13.8848658,1.01405931 15.7253962,1.01405931 17.3946683,0.474836241 C19.3493748,-0.156590392 21.4804757,-0.156590392 23.7730642,0.464766813 C24.1675658,0.571688056 24.4028795,0.978750283 24.3004409,1.37706022 L22.6175026,7.69469656 C22.5324098,8.02556139 22.2359718,8.25663757 21.896612,8.25663757 L13.2202372,8.25663757 C12.8757532,8.25663757 12.5762326,8.01867532 12.4960236,7.68126633 Z" id="Path-3-Copy-9"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="intro-bold">
                          { (sizes.includes('110ct')) ? (<span>110ct</span>) : null }
                          { (sizes.includes('185ct')) ? (<span>185ct</span>) : null }
                        </div>
                      </div>
                    )
                : null }

                { (sizes.includes('230ct'))
                  ? (
                      <div className="box-size">
                        <svg width="35px" height="21px" viewBox="0 0 35 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="03-Product-Page" transform="translate(-136.000000, -564.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g id="Group-12" transform="translate(136.000000, 564.000000)">
                                        <path d="M6.82234245,0.773198811 L8.33841855,0.773198811 C8.33841855,4.97471135 12.3845766,8.44015592 17.4348752,8.44015592 C22.4851737,8.44015592 26.5313318,4.97471135 26.5313318,0.773198811 L28.0474079,0.773198811 C28.0474079,5.89442507 23.2695508,9.98655354 17.4348752,9.98655354 C11.6001995,9.98655354 6.82234245,5.89442507 6.82234245,0.773198811 Z" id="Oval-6"></path>
                                        <g id="Group-10-Copy">
                                            <path d="M1.5160761,1.74810166 L1.5160761,18.3550674 L33.3536742,18.3550674 L33.3536742,1.74810166 L1.5160761,1.74810166 Z M1,0 L33.8697503,0 C34.4220351,-1.01453063e-16 34.8697503,0.44771525 34.8697503,1 L34.8697503,19.1031691 C34.8697503,19.6554538 34.4220351,20.1031691 33.8697503,20.1031691 L1,20.1031691 C0.44771525,20.1031691 2.02974496e-15,19.6554538 0,19.1031691 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" id="Rectangle-2-Copy-11"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="intro-bold">
                          <span>230ct</span>
                        </div>
                      </div>
                    )
                : null }



              { (data.nodeProduct.field_has_multipacks_ === true) ? (
                <div className="medium-label"><a href="#available" className="anchor">Available in <span className="medium-label-span">multipacks</span></a></div>
              ) : null}
            </div>
            { soothingaloe ?
              <>
              <a href="#shopscotties" className="anchor button small-button transparent-button">Where to Buy</a>
              <br/><br/>
              </>:null
            }
          </div>

            {(!tripleSoft && !soothingaloe) ? <div className="internal-header-mark"><img src={softness} alt="" /></div> : null }

          </div>
          <div className="internal-header-text mobile">
            <h1 className="white-sans-title blue-text">{data.nodeProduct.title}{ (tripleSoft) ? <sup>®</sup> : null} { (soothingaloe) ? <> triple soft<sup>®</sup></> : null}</h1>

            <div
              className="rating_summary"
              data-bv-show="rating_summary"
              data-bv-product-id={data.nodeProduct.relationships.field_bazaarvoice_products[0].field_bazaarvoice_id}
              onClick={this.scrollToReviews}
              data-bv-seo="false"
            />

            <div className="medium-text grey-text" dangerouslySetInnerHTML={{__html: data.nodeProduct.field_product_details.value }}></div>
            <div className="box-sizes">
              <div className="bold-label">Box Sizes</div>
              { ((sizes.includes('54ct')) || sizes.includes('64ct') || sizes.includes('66ct') || sizes.includes('92ct') || sizes.includes('66ct'))
                ? (
                    <div className="box-size">
                      <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                          <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="03-Product-Page" transform="translate(-143.000000, -462.000000)" fill="#FFFFFF" fillRule="nonzero">
                                  <g id="Group-5" transform="translate(143.000000, 462.000000)">
                                      <path d="M1.54825848,8.06268311 L1.54825848,21.9891357 L17.6736247,21.9891357 L17.6736247,8.06268311 L1.54825848,8.06268311 Z M1.0823161,6.59674072 L18.1395671,6.59674072 C18.6918518,6.59674072 19.1395671,7.04445597 19.1395671,7.59674072 L19.1395671,22.4550781 C19.1395671,23.0073629 18.6918518,23.4550781 18.1395671,23.4550781 L1.0823161,23.4550781 C0.530031351,23.4550781 0.0823161006,23.0073629 0.0823161006,22.4550781 L0.0823161006,7.59674072 C0.0823161006,7.04445597 0.530031351,6.59674072 1.0823161,6.59674072 Z" id="Rectangle-2-Copy-8"></path>
                                      <path d="M13.2515765,6.6054197 L14.5786996,1.70056372 C12.8601908,1.34047574 11.2824473,1.39584676 9.83544672,1.85995467 C8.25017291,2.36841203 6.54702637,2.45792099 4.73421467,2.13398643 L5.85713713,6.6054197 L13.2515765,6.6054197 Z M4.56611239,7.50686257 L3.03499243,1.33266865 C2.90555942,0.792054938 3.4014943,0.309759245 3.93828927,0.454214246 C5.93311595,0.991035013 7.74470505,0.991035013 9.38772883,0.464055047 C11.3116988,-0.153034995 13.4092894,-0.153034995 15.6658283,0.454214246 C16.0541266,0.558707834 16.2857402,0.956527681 16.1849123,1.34579396 L14.5284373,7.51998788 C14.4446824,7.84334039 14.1529058,8.06916997 13.8188822,8.06916997 L5.2789382,8.06916997 C4.939871,8.06916997 4.64506023,7.83661067 4.56611239,7.50686257 Z" id="Path-3-Copy-8"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      <div className="intro-bold">
                        { (sizes.includes('54ct')) ? (<span>54ct</span>) : null }
                        { (sizes.includes('66ct')) ? (<span>66ct</span>) : null }
                        { (sizes.includes('64ct')) ? (<span>64ct</span>) : null }
                        { (sizes.includes('92ct')) ? (<span>92ct</span>) : null }
                      </div>
                    </div>
                    )
                : null }
                { ((sizes.includes('110ct')) || sizes.includes('185ct'))
                  ? (
                      <div className="box-size">
                        <svg width="35px" height="24px" viewBox="0 0 35 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="03-Product-Page" transform="translate(-136.000000, -510.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g id="Group-6" transform="translate(136.000000, 510.000000)">
                                        <path d="M1.9831491,8.25 L1.9831491,22.5 L33.2597448,22.5 L33.2597448,8.25 L1.9831491,8.25 Z M1.4937874,6.75 L33.7491065,6.75 C34.3013913,6.75 34.7491065,7.19771525 34.7491065,7.75 L34.7491065,23 C34.7491065,23.5522847 34.3013913,24 33.7491065,24 L1.4937874,24 C0.94150265,24 0.4937874,23.5522847 0.4937874,23 L0.4937874,7.75 C0.4937874,7.19771525 0.94150265,6.75 1.4937874,6.75 Z" id="Rectangle-2-Copy-9"></path>
                                        <path d="M21.3202432,6.75888062 L22.6685679,1.7400722 C20.9226049,1.37161845 19.319656,1.42827587 17.8495387,1.90316621 C16.2389392,2.42343634 14.5085839,2.51502483 12.6668114,2.18356443 L13.8076732,6.75888062 L21.3202432,6.75888062 Z M12.4960236,7.68126633 L10.940443,1.36362998 C10.8089423,0.810456415 11.3128,0.31695575 11.8581706,0.464766813 C13.8848658,1.01405931 15.7253962,1.01405931 17.3946683,0.474836241 C19.3493748,-0.156590392 21.4804757,-0.156590392 23.7730642,0.464766813 C24.1675658,0.571688056 24.4028795,0.978750283 24.3004409,1.37706022 L22.6175026,7.69469656 C22.5324098,8.02556139 22.2359718,8.25663757 21.896612,8.25663757 L13.2202372,8.25663757 C12.8757532,8.25663757 12.5762326,8.01867532 12.4960236,7.68126633 Z" id="Path-3-Copy-9"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="intro-bold">
                          { (sizes.includes('110ct')) ? (<span>110ct</span>) : null }
                          { (sizes.includes('185ct')) ? (<span>185ct</span>) : null }
                        </div>
                      </div>
                    )
                : null }

                { sizes.includes('230ct')
                  ? (
                      <div className="box-size">
                        <svg width="35px" height="21px" viewBox="0 0 35 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="03-Product-Page" transform="translate(-136.000000, -564.000000)" fill="#FFFFFF" fillRule="nonzero">
                                    <g id="Group-12" transform="translate(136.000000, 564.000000)">
                                        <path d="M6.82234245,0.773198811 L8.33841855,0.773198811 C8.33841855,4.97471135 12.3845766,8.44015592 17.4348752,8.44015592 C22.4851737,8.44015592 26.5313318,4.97471135 26.5313318,0.773198811 L28.0474079,0.773198811 C28.0474079,5.89442507 23.2695508,9.98655354 17.4348752,9.98655354 C11.6001995,9.98655354 6.82234245,5.89442507 6.82234245,0.773198811 Z" id="Oval-6"></path>
                                        <g id="Group-10-Copy">
                                            <path d="M1.5160761,1.74810166 L1.5160761,18.3550674 L33.3536742,18.3550674 L33.3536742,1.74810166 L1.5160761,1.74810166 Z M1,0 L33.8697503,0 C34.4220351,-1.01453063e-16 34.8697503,0.44771525 34.8697503,1 L34.8697503,19.1031691 C34.8697503,19.6554538 34.4220351,20.1031691 33.8697503,20.1031691 L1,20.1031691 C0.44771525,20.1031691 2.02974496e-15,19.6554538 0,19.1031691 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" id="Rectangle-2-Copy-11"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className="intro-bold">
                          <span>230ct</span>
                        </div>
                      </div>
                    )
                : null }
            </div>
            <div className="internal-header-buttons">
              <a href="#buyonline" className={ (tripleSoft) ? "anchor button small-button not-ultra-plush pink-color": "anchor button small-button not-ultra-plush"}>Shop Online</a>
              {/*  <a href="#buyultraonline" className="anchor button small-button ultra-plush">Buy Online</a> */}
              <a href="#shopscotties" className="anchor button small-button transparent-button">Where to Buy</a>
            </div>

            { (data.nodeProduct.field_has_multipacks_ === true) ? (
              <div className="medium-label blue-text margin"><a href="#available">Available in <span className="medium-label-span">multipacks</span></a></div>
            ) : null}
          </div>


        { /*  This internal-header-text mobile seems like a duplicate?
        <div className="internal-header-text mobile">
          <h1 className="white-sans-title blue-text">{data.nodeProduct.title}</h1>
          <div className="medium-text grey-text" dangerouslySetInnerHTML={{__html: data.nodeProduct.field_product_details.value }}></div>
          <div className="box-sizes">
            <div className="bold-label dark-grey-text">Box Sizes</div>
              { ((sizes.includes('54ct')) || sizes.includes('64ct') || sizes.includes('66ct') || sizes.includes('92ct'))
                ? (
                    <div className="box-size">
                      <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                          <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="03-Product-Page" transform="translate(-143.000000, -462.000000)" fill="#FFFFFF" fillRule="nonzero">
                                  <g id="Group-5" transform="translate(143.000000, 462.000000)">
                                      <path d="M1.54825848,8.06268311 L1.54825848,21.9891357 L17.6736247,21.9891357 L17.6736247,8.06268311 L1.54825848,8.06268311 Z M1.0823161,6.59674072 L18.1395671,6.59674072 C18.6918518,6.59674072 19.1395671,7.04445597 19.1395671,7.59674072 L19.1395671,22.4550781 C19.1395671,23.0073629 18.6918518,23.4550781 18.1395671,23.4550781 L1.0823161,23.4550781 C0.530031351,23.4550781 0.0823161006,23.0073629 0.0823161006,22.4550781 L0.0823161006,7.59674072 C0.0823161006,7.04445597 0.530031351,6.59674072 1.0823161,6.59674072 Z" id="Rectangle-2-Copy-8"></path>
                                      <path d="M13.2515765,6.6054197 L14.5786996,1.70056372 C12.8601908,1.34047574 11.2824473,1.39584676 9.83544672,1.85995467 C8.25017291,2.36841203 6.54702637,2.45792099 4.73421467,2.13398643 L5.85713713,6.6054197 L13.2515765,6.6054197 Z M4.56611239,7.50686257 L3.03499243,1.33266865 C2.90555942,0.792054938 3.4014943,0.309759245 3.93828927,0.454214246 C5.93311595,0.991035013 7.74470505,0.991035013 9.38772883,0.464055047 C11.3116988,-0.153034995 13.4092894,-0.153034995 15.6658283,0.454214246 C16.0541266,0.558707834 16.2857402,0.956527681 16.1849123,1.34579396 L14.5284373,7.51998788 C14.4446824,7.84334039 14.1529058,8.06916997 13.8188822,8.06916997 L5.2789382,8.06916997 C4.939871,8.06916997 4.64506023,7.83661067 4.56611239,7.50686257 Z" id="Path-3-Copy-8"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      <div className="intro-bold">
                        { (sizes.includes('54ct')) ? (<span>54ct</span>) : null }
                        { (sizes.includes('64ct')) ? (<span>64ct</span>) : null }
                        { (sizes.includes('66ct')) ? (<span>66ct</span>) : null }
                        { (sizes.includes('92ct')) ? (<span>92ct</span>) : null }
                      </div>
                    </div>
                  )
              : null }
              { ((sizes.includes('110ct')) || sizes.includes('185ct'))
                ? (
                    <div className="box-size">
                      <svg width="35px" height="24px" viewBox="0 0 35 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="03-Product-Page" transform="translate(-136.000000, -510.000000)" fill="#FFFFFF" fillRule="nonzero">
                                  <g id="Group-6" transform="translate(136.000000, 510.000000)">
                                      <path d="M1.9831491,8.25 L1.9831491,22.5 L33.2597448,22.5 L33.2597448,8.25 L1.9831491,8.25 Z M1.4937874,6.75 L33.7491065,6.75 C34.3013913,6.75 34.7491065,7.19771525 34.7491065,7.75 L34.7491065,23 C34.7491065,23.5522847 34.3013913,24 33.7491065,24 L1.4937874,24 C0.94150265,24 0.4937874,23.5522847 0.4937874,23 L0.4937874,7.75 C0.4937874,7.19771525 0.94150265,6.75 1.4937874,6.75 Z" id="Rectangle-2-Copy-9"></path>
                                      <path d="M21.3202432,6.75888062 L22.6685679,1.7400722 C20.9226049,1.37161845 19.319656,1.42827587 17.8495387,1.90316621 C16.2389392,2.42343634 14.5085839,2.51502483 12.6668114,2.18356443 L13.8076732,6.75888062 L21.3202432,6.75888062 Z M12.4960236,7.68126633 L10.940443,1.36362998 C10.8089423,0.810456415 11.3128,0.31695575 11.8581706,0.464766813 C13.8848658,1.01405931 15.7253962,1.01405931 17.3946683,0.474836241 C19.3493748,-0.156590392 21.4804757,-0.156590392 23.7730642,0.464766813 C24.1675658,0.571688056 24.4028795,0.978750283 24.3004409,1.37706022 L22.6175026,7.69469656 C22.5324098,8.02556139 22.2359718,8.25663757 21.896612,8.25663757 L13.2202372,8.25663757 C12.8757532,8.25663757 12.5762326,8.01867532 12.4960236,7.68126633 Z" id="Path-3-Copy-9"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      <div className="intro-bold">
                        { (sizes.includes('110ct')) ? (<span>110ct</span>) : null }
                        { (sizes.includes('185ct')) ? (<span>185ct</span>) : null }
                      </div>
                    </div>
                  )
              : null }

              { sizes.includes('230ct')
                ? (
                    <div className="box-size">
                      <svg width="35px" height="21px" viewBox="0 0 35 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="03-Product-Page" transform="translate(-136.000000, -564.000000)" fill="#FFFFFF" fillRule="nonzero">
                                  <g id="Group-12" transform="translate(136.000000, 564.000000)">
                                      <path d="M6.82234245,0.773198811 L8.33841855,0.773198811 C8.33841855,4.97471135 12.3845766,8.44015592 17.4348752,8.44015592 C22.4851737,8.44015592 26.5313318,4.97471135 26.5313318,0.773198811 L28.0474079,0.773198811 C28.0474079,5.89442507 23.2695508,9.98655354 17.4348752,9.98655354 C11.6001995,9.98655354 6.82234245,5.89442507 6.82234245,0.773198811 Z" id="Oval-6"></path>
                                      <g id="Group-10-Copy">
                                          <path d="M1.5160761,1.74810166 L1.5160761,18.3550674 L33.3536742,18.3550674 L33.3536742,1.74810166 L1.5160761,1.74810166 Z M1,0 L33.8697503,0 C34.4220351,-1.01453063e-16 34.8697503,0.44771525 34.8697503,1 L34.8697503,19.1031691 C34.8697503,19.6554538 34.4220351,20.1031691 33.8697503,20.1031691 L1,20.1031691 C0.44771525,20.1031691 2.02974496e-15,19.6554538 0,19.1031691 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" id="Rectangle-2-Copy-11"></path>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </svg>
                      <div className="intro-bold">
                        <span>230ct</span>
                      </div>
                    </div>
                  )
              : null }
          </div>
          <div className="internal-header-buttons">
            <a href="#buyonline" className="button small-button blue-background white-text">Shop Online</a>
            <a href="#shopscotties" className="button small-button transparent-button blue-outline no-margin">Where to Buy</a>
          </div>

          { (data.nodeProduct.field_has_multipacks_ === true) ? (
            <div className="medium-label blue-text margin"><a href="#available">Available in <span className="medium-label-span">multipacks</span></a></div>
          ) : null}
        </div>
         */ }


      <div className="box-styles-match">
        <div className="box-style-match-text">
          <div className="bold-label font-22 font-dark-grey">Find the style that's right for you</div>
          <div className="styles-match-buttons">
          <ul className="navigation-list">
          { sizes.includes('54ct') ?
            (
              <li>
                <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '54ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'54ct')}>
                  <div className="style-match-button">
                    <div className="style-match-button-wrapper">
                      <svg width="17px" height="21px" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.360677083"><g id="03-Product-Page" transform="translate(-468.000000, -906.000000)"><g id="Group-8-Copy" transform="translate(468.000000, 906.000000)"><path d="M1.2804988,7.28283691 L1.2804988,19.4475755 L15.3659856,19.4475755 L15.3659856,7.28283691 L1.2804988,7.28283691 Z M1,6.00233812 L15.6464844,6.00233812 C16.1987691,6.00233812 16.6464844,6.45005337 16.6464844,7.00233812 L16.6464844,19.7280743 C16.6464844,20.280359 16.1987691,20.7280743 15.6464844,20.7280743 L1,20.7280743 C0.44771525,20.7280743 1.60511748e-16,20.280359 -8.8817842e-16,19.7280743 L0,7.00233812 C-6.76353751e-17,6.45005337 0.44771525,6.00233812 1,6.00233812 Z" id="Rectangle-2-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><path d="M11.5033321,6.00991919 L12.6625724,1.72553376 C11.1614572,1.41099737 9.78330032,1.45936388 8.51934713,1.86476155 C7.13461249,2.30889841 5.64691616,2.38708437 4.06342745,2.10412795 L5.04429879,6.00991919 L11.5033321,6.00991919 Z M3.91659033,6.79732839 L2.579159,1.40417789 C2.46609943,0.931952501 2.89929792,0.510667829 3.36818764,0.636849088 C5.11066619,1.10576134 6.69308695,1.10576134 8.12826613,0.645445016 C9.80885141,0.106417652 11.6410941,0.106417652 13.612178,0.636849088 C13.9513561,0.728124103 14.1536702,1.07561923 14.0655972,1.41564283 L12.6186684,6.80879333 C12.5455086,7.09124133 12.2906421,7.28850318 11.998873,7.28850318 L4.53924276,7.28850318 C4.243068,7.28850318 3.98555117,7.08536293 3.91659033,6.79732839 Z" id="Path-3-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-6" fill="#E6EEF3" x="1.2804988" y="4.72183932" width="3.84149639" height="1.2804988"></rect><rect id="Rectangle-16-Copy-8" fill="#E6EEF3" x="10.2447054" y="4.72183932" width="3.84149639" height="1.2804988"></rect></g></g></g></svg>
                      <div className="style-match-button-text">54ct</div>
                    </div>
                  </div>
                </a>
              </li>
              ) : null }
            { sizes.includes('64ct') ?
              (
                <li>
                  <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '64ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'64ct')}>
                    <div className="style-match-button">
                      <div className="style-match-button-wrapper">
                        <svg width="17px" height="21px" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.360677083"><g id="03-Product-Page" transform="translate(-468.000000, -906.000000)"><g id="Group-8-Copy" transform="translate(468.000000, 906.000000)"><path d="M1.2804988,7.28283691 L1.2804988,19.4475755 L15.3659856,19.4475755 L15.3659856,7.28283691 L1.2804988,7.28283691 Z M1,6.00233812 L15.6464844,6.00233812 C16.1987691,6.00233812 16.6464844,6.45005337 16.6464844,7.00233812 L16.6464844,19.7280743 C16.6464844,20.280359 16.1987691,20.7280743 15.6464844,20.7280743 L1,20.7280743 C0.44771525,20.7280743 1.60511748e-16,20.280359 -8.8817842e-16,19.7280743 L0,7.00233812 C-6.76353751e-17,6.45005337 0.44771525,6.00233812 1,6.00233812 Z" id="Rectangle-2-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><path d="M11.5033321,6.00991919 L12.6625724,1.72553376 C11.1614572,1.41099737 9.78330032,1.45936388 8.51934713,1.86476155 C7.13461249,2.30889841 5.64691616,2.38708437 4.06342745,2.10412795 L5.04429879,6.00991919 L11.5033321,6.00991919 Z M3.91659033,6.79732839 L2.579159,1.40417789 C2.46609943,0.931952501 2.89929792,0.510667829 3.36818764,0.636849088 C5.11066619,1.10576134 6.69308695,1.10576134 8.12826613,0.645445016 C9.80885141,0.106417652 11.6410941,0.106417652 13.612178,0.636849088 C13.9513561,0.728124103 14.1536702,1.07561923 14.0655972,1.41564283 L12.6186684,6.80879333 C12.5455086,7.09124133 12.2906421,7.28850318 11.998873,7.28850318 L4.53924276,7.28850318 C4.243068,7.28850318 3.98555117,7.08536293 3.91659033,6.79732839 Z" id="Path-3-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-6" fill="#E6EEF3" x="1.2804988" y="4.72183932" width="3.84149639" height="1.2804988"></rect><rect id="Rectangle-16-Copy-8" fill="#E6EEF3" x="10.2447054" y="4.72183932" width="3.84149639" height="1.2804988"></rect></g></g></g></svg>
                        <div className="style-match-button-text">64ct</div>
                      </div>
                    </div>
                </a>
              </li>
            ) : null }
            { sizes.includes('66ct') ?
              (
                <li>
                <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '66ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'66ct')}>
                  <div className="style-match-button">
                    <div className="style-match-button-wrapper">
                      <svg width="17px" height="21px" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.360677083"><g id="03-Product-Page" transform="translate(-468.000000, -906.000000)"><g id="Group-8-Copy" transform="translate(468.000000, 906.000000)"><path d="M1.2804988,7.28283691 L1.2804988,19.4475755 L15.3659856,19.4475755 L15.3659856,7.28283691 L1.2804988,7.28283691 Z M1,6.00233812 L15.6464844,6.00233812 C16.1987691,6.00233812 16.6464844,6.45005337 16.6464844,7.00233812 L16.6464844,19.7280743 C16.6464844,20.280359 16.1987691,20.7280743 15.6464844,20.7280743 L1,20.7280743 C0.44771525,20.7280743 1.60511748e-16,20.280359 -8.8817842e-16,19.7280743 L0,7.00233812 C-6.76353751e-17,6.45005337 0.44771525,6.00233812 1,6.00233812 Z" id="Rectangle-2-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><path d="M11.5033321,6.00991919 L12.6625724,1.72553376 C11.1614572,1.41099737 9.78330032,1.45936388 8.51934713,1.86476155 C7.13461249,2.30889841 5.64691616,2.38708437 4.06342745,2.10412795 L5.04429879,6.00991919 L11.5033321,6.00991919 Z M3.91659033,6.79732839 L2.579159,1.40417789 C2.46609943,0.931952501 2.89929792,0.510667829 3.36818764,0.636849088 C5.11066619,1.10576134 6.69308695,1.10576134 8.12826613,0.645445016 C9.80885141,0.106417652 11.6410941,0.106417652 13.612178,0.636849088 C13.9513561,0.728124103 14.1536702,1.07561923 14.0655972,1.41564283 L12.6186684,6.80879333 C12.5455086,7.09124133 12.2906421,7.28850318 11.998873,7.28850318 L4.53924276,7.28850318 C4.243068,7.28850318 3.98555117,7.08536293 3.91659033,6.79732839 Z" id="Path-3-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-6" fill="#E6EEF3" x="1.2804988" y="4.72183932" width="3.84149639" height="1.2804988"></rect><rect id="Rectangle-16-Copy-8" fill="#E6EEF3" x="10.2447054" y="4.72183932" width="3.84149639" height="1.2804988"></rect></g></g></g></svg>
                      <div className="style-match-button-text">66ct</div>
                    </div>
                  </div>
                </a>
                </li>
              ) : null }
          { sizes.includes('92ct') ?
            (
              <li>
              <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '92ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'92ct')}>
                <div className="style-match-button">
                  <div className="style-match-button-wrapper">
                    <svg width="17px" height="21px" viewBox="0 0 17 21" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.360677083"><g id="03-Product-Page" transform="translate(-468.000000, -906.000000)"><g id="Group-8-Copy" transform="translate(468.000000, 906.000000)"><path d="M1.2804988,7.28283691 L1.2804988,19.4475755 L15.3659856,19.4475755 L15.3659856,7.28283691 L1.2804988,7.28283691 Z M1,6.00233812 L15.6464844,6.00233812 C16.1987691,6.00233812 16.6464844,6.45005337 16.6464844,7.00233812 L16.6464844,19.7280743 C16.6464844,20.280359 16.1987691,20.7280743 15.6464844,20.7280743 L1,20.7280743 C0.44771525,20.7280743 1.60511748e-16,20.280359 -8.8817842e-16,19.7280743 L0,7.00233812 C-6.76353751e-17,6.45005337 0.44771525,6.00233812 1,6.00233812 Z" id="Rectangle-2-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><path d="M11.5033321,6.00991919 L12.6625724,1.72553376 C11.1614572,1.41099737 9.78330032,1.45936388 8.51934713,1.86476155 C7.13461249,2.30889841 5.64691616,2.38708437 4.06342745,2.10412795 L5.04429879,6.00991919 L11.5033321,6.00991919 Z M3.91659033,6.79732839 L2.579159,1.40417789 C2.46609943,0.931952501 2.89929792,0.510667829 3.36818764,0.636849088 C5.11066619,1.10576134 6.69308695,1.10576134 8.12826613,0.645445016 C9.80885141,0.106417652 11.6410941,0.106417652 13.612178,0.636849088 C13.9513561,0.728124103 14.1536702,1.07561923 14.0655972,1.41564283 L12.6186684,6.80879333 C12.5455086,7.09124133 12.2906421,7.28850318 11.998873,7.28850318 L4.53924276,7.28850318 C4.243068,7.28850318 3.98555117,7.08536293 3.91659033,6.79732839 Z" id="Path-3-Copy-8" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-6" fill="#E6EEF3" x="1.2804988" y="4.72183932" width="3.84149639" height="1.2804988"></rect><rect id="Rectangle-16-Copy-8" fill="#E6EEF3" x="10.2447054" y="4.72183932" width="3.84149639" height="1.2804988"></rect></g></g></g></svg>
                    <div className="style-match-button-text">92ct</div>
                    </div>
                  </div>
                </a>
                </li>
              ) : null }
            { sizes.includes('110ct') ?
              (
                <li>
                <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '110ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'110ct')}>
                  <div className="style-match-button">
                    <div className="style-match-button-wrapper">
                      <svg width="28px" height="19px" viewBox="0 0 28 19" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.359205163"><g id="03-Product-Page" transform="translate(-590.000000, -907.000000)"><g id="Group-9" transform="translate(590.000000, 907.000000)"><path d="M1.4865424,6.62911403 L1.4865424,17.7019199 L25.9632711,17.7019199 L25.9632711,6.62911403 L1.4865424,6.62911403 Z M1.32098389,5.46355552 L26.1288296,5.46355552 C26.6811144,5.46355552 27.1288296,5.91127077 27.1288296,6.46355552 L27.1288296,17.8674784 C27.1288296,18.4197631 26.6811144,18.8674784 26.1288296,18.8674784 L1.32098389,18.8674784 C0.768699137,18.8674784 0.320983887,18.4197631 0.320983887,17.8674784 L0.320983887,6.46355552 C0.320983887,5.91127077 0.768699137,5.46355552 1.32098389,5.46355552 Z" id="Rectangle-2-Copy-9" fill="#2B87BB" fillRule="nonzero"></path><path d="M16.6195451,5.4704561 L17.6747295,1.57064619 C16.3083576,1.28434326 15.053907,1.32836829 13.9034089,1.6973766 C12.642971,2.10164679 11.2888134,2.17281461 9.84746203,1.91525696 L10.7402883,5.4704561 L16.6195451,5.4704561 Z M9.71380532,6.18718578 L8.49642466,1.27813591 C8.39351356,0.848298468 8.78782723,0.464829202 9.21462841,0.579684164 C10.8006984,1.00650586 12.2410777,1.00650586 13.5474322,0.587508502 C15.0771645,0.0968653779 16.7449413,0.0968653779 18.5390965,0.579684164 C18.8478292,0.66276614 19.0319832,0.979069369 18.9518158,1.28857172 L17.6347664,6.1976216 C17.5681736,6.45471647 17.3361844,6.63427168 17.0706051,6.63427168 L10.2805671,6.63427168 C10.0109777,6.63427168 9.77657608,6.44936573 9.71380532,6.18718578 Z" id="Path-3-Copy-9" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-7" fill="#E6EEF3" x="8.47989346" y="4.29799701" width="3.49667553" height="1.16555851"></rect><rect id="Rectangle-16-Copy-9" fill="#E6EEF3" x="15.4738953" y="4.29799701" width="3.49667553" height="1.16555851"></rect></g></g></g></svg>
                      <div className="style-match-button-text">110ct</div>
                    </div>
                  </div>
                </a>
                </li>
              ) : null }
            { sizes.includes('185ct') ?
              (
                <li>
                <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '185ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'185ct')}>
                  <div className="style-match-button">
                    <div className="style-match-button-wrapper">
                      <svg width="28px" height="19px" viewBox="0 0 28 19" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.359205163"><g id="03-Product-Page" transform="translate(-590.000000, -907.000000)"><g id="Group-9" transform="translate(590.000000, 907.000000)"><path d="M1.4865424,6.62911403 L1.4865424,17.7019199 L25.9632711,17.7019199 L25.9632711,6.62911403 L1.4865424,6.62911403 Z M1.32098389,5.46355552 L26.1288296,5.46355552 C26.6811144,5.46355552 27.1288296,5.91127077 27.1288296,6.46355552 L27.1288296,17.8674784 C27.1288296,18.4197631 26.6811144,18.8674784 26.1288296,18.8674784 L1.32098389,18.8674784 C0.768699137,18.8674784 0.320983887,18.4197631 0.320983887,17.8674784 L0.320983887,6.46355552 C0.320983887,5.91127077 0.768699137,5.46355552 1.32098389,5.46355552 Z" id="Rectangle-2-Copy-9" fill="#2B87BB" fillRule="nonzero"></path><path d="M16.6195451,5.4704561 L17.6747295,1.57064619 C16.3083576,1.28434326 15.053907,1.32836829 13.9034089,1.6973766 C12.642971,2.10164679 11.2888134,2.17281461 9.84746203,1.91525696 L10.7402883,5.4704561 L16.6195451,5.4704561 Z M9.71380532,6.18718578 L8.49642466,1.27813591 C8.39351356,0.848298468 8.78782723,0.464829202 9.21462841,0.579684164 C10.8006984,1.00650586 12.2410777,1.00650586 13.5474322,0.587508502 C15.0771645,0.0968653779 16.7449413,0.0968653779 18.5390965,0.579684164 C18.8478292,0.66276614 19.0319832,0.979069369 18.9518158,1.28857172 L17.6347664,6.1976216 C17.5681736,6.45471647 17.3361844,6.63427168 17.0706051,6.63427168 L10.2805671,6.63427168 C10.0109777,6.63427168 9.77657608,6.44936573 9.71380532,6.18718578 Z" id="Path-3-Copy-9" fill="#2B87BB" fillRule="nonzero"></path><rect id="Rectangle-16-Copy-7" fill="#E6EEF3" x="8.47989346" y="4.29799701" width="3.49667553" height="1.16555851"></rect><rect id="Rectangle-16-Copy-9" fill="#E6EEF3" x="15.4738953" y="4.29799701" width="3.49667553" height="1.16555851"></rect></g></g></g></svg>
                      <div className="style-match-button-text">185ct</div>
                    </div>
                  </div>
                </a>
                </li>
              ) : null }
            { sizes.includes('230ct') ?
              (
                <li>
                <a href="#" className={"style-match-button-link last w-inline-block " + (this.state.size === '230ct' ? 'active' : '') } onClick={(e) => this.handleSize(e,'230ct')}>
                  <div className="style-match-button">
                    <div className="style-match-button-wrapper">
                      <svg width="28px" height="16px" viewBox="0 0 28 16" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.357846467"><g id="03-Product-Page" transform="translate(-834.000000, -910.000000)"><g id="Group-12-Copy" transform="translate(834.000000, 910.000000)"><path d="M5.29979286,0.588865873 L6.4775246,0.588865873 C6.4775246,3.7887251 9.62069717,6.42799719 13.5439151,6.42799719 C17.467133,6.42799719 20.6103056,3.7887251 20.6103056,0.588865873 L21.7880373,0.588865873 C21.7880373,4.48917629 18.0764598,7.60572893 13.5439151,7.60572893 C9.01137031,7.60572893 5.29979286,4.48917629 5.29979286,0.588865873 Z" id="Oval-6" fill="#2B87BB" fillRule="nonzero"></path><g id="Group-10-Copy" fill="#2B87BB" fillRule="nonzero"><path d="M1.17773175,1.33134893 L1.17773175,13.9791638 L25.9100984,13.9791638 L25.9100984,1.33134893 L1.17773175,1.33134893 Z M1,0 L26.0878302,0 C26.6401149,-2.06356265e-15 27.0878302,0.44771525 27.0878302,1 L27.0878302,14.3105127 C27.0878302,14.8627974 26.6401149,15.3105127 26.0878302,15.3105127 L1,15.3105127 C0.44771525,15.3105127 6.76353751e-17,14.8627974 0,14.3105127 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z" id="Rectangle-2-Copy-11"></path></g><rect id="Rectangle-16-Copy-11" fill="#E6EEF3" x="4.56141026" y="1.33720427" width="3.53319524" height="1.17773175"></rect><rect id="Rectangle-16-Copy-13" fill="#E6EEF3" x="19.0652515" y="1.33720427" width="3.53319524" height="1.17773175"></rect></g></g></g></svg>
                      <div className="style-match-button-text">230ct</div>
                    </div>
                  </div>
                </a>
                </li>
              ) : null }
              </ul>
            </div>
          </div>
          <div className="box-style-match-images">
            <div className="box-match-styles-images-wrapper">
              {this.state.variations.filter((el) => {
                return el.relationships.field_product_sizes.name === this.state.size
              }).map(variation => {
                return (
                  <div key={variation.id} className="box-style-match-image" onClick={(e) => this.handleChange(e,variation.id)}>
                    <img src={variation.relationships.field_image.localFile.childImageSharp.resolutions.src} alt="" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className={"modal " + (this.state.showModal ? 'active' : '') }>
          <div className="modal-container">
            <div className="modal-wrapper">
              <div className="modal-side-bar">
                <div className="side-bar-wrapper">
                  <div className="side-bar-title bold-label">Styles</div>
                  <div className="side-bar-images">

                    {this.state.variations.map(variation => {
                      return (
                        <div key={variation.id} className="side-bar-image" onClick={(e) => this.handleChange(e,variation.id)}>
                          <img src={variation.relationships.field_image.localFile.childImageSharp.resolutions.src} className="sidebar-style-image" alt="" />
                        </div>
                      )
                    })}

                  </div>
                </div>
                <div className="sidebar-gradient"></div>
              </div>
              <div className="modal-content">
                <div className="bold-label mobile-modal-title">Styles</div>
                <div className="modal-close" onClick={this.handleShowModal}><img src={close} alt="" /></div>
                <div className="modal-content-slider">
                  <div className="slider-image"><img src={this.state.variations[this.state.variation].relationships.field_box_image.localFile.childImageSharp.resolutions.src} alt="" /></div>
                  <div className="slider-text medium-label">Available in <span className="slider-text-count">{this.state.variations[this.state.variation].relationships.field_product_sizes.name}</span></div>
                </div>
                <div className="modal-content-slider-nav">
                  <div className="prev" onClick={this.handlePrev}><img src={prev} alt="" /></div>
                  <div className="next" onClick={this.handleNext}><img src={next} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-styles-match">
          <div className="bold-label font-22 font-dark-grey bazaarvoice-header">Customer Reviews</div>

          <div
            data-bv-show="reviews"
            data-bv-product-id={data.nodeProduct.relationships.field_bazaarvoice_products[0].field_bazaarvoice_id}
            id="review-holder"
          />
          <div
            data-bv-show="questions"
            data-bv-product-id={data.nodeProduct.relationships.field_bazaarvoice_products[0].field_bazaarvoice_id}
            id="questions-holder"
          />
        </div>


            {data.nodeProduct.relationships.field_paragraph_content.map(paragraph => {
              switch(paragraph.__typename) {
                case 'paragraph__buy_online':
                  return (
                    <BuyOnline key={paragraph.id} data={paragraph} />
                  );
                  break;
                case 'paragraph__shop_scotties':
                  return (
                    <ShopScotties key={paragraph.id} data={paragraph} stores={data.nodeProduct.relationships.field_product_store} />
                  );
                  break;
                case 'paragraph__four_icon_grid':
                  return (
                    <FourIconGrid key={paragraph.id} data={paragraph} />
                  );
                  break;
              }
            })}

            <div className="product-switcher">
              <div className="intro-light font-38 dark-grey-text">Discover Other Scotties Products</div>

              <div className="two-col-grid">
                <div className={"column-item" + ' ' + others[0].node.title.replace(/\s/g, '')} id={others[0].node.id}>
                  <div className="new-look-badge" />
                  <div
                    className="two-col-grid-item-wrapper"
                    style={{
                      backgroundImage: `url(${others[0].node.relationships.field_image.localFile.childImageSharp.sizes.src})`,
                    }}>
                    <div className="two-col-item-image" />
                    <div className="two-col-item-text">
                      <div className="two-col-text-title white-sans-title">{others[0].node.title}
                      {(others[0].node.id === 'f3534e4d-130c-5d93-a64e-f18aca9518ea') ? <> triple soft<sup>&trade;</sup></> : null}
                      {(!tripleSoft && !soothingaloe) ? <sup>®</sup> : null}
                      </div>
                      <div className="arrow-link">
                        <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                          <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                            <g id="02-Products" transform="translate(-174.000000, -1708.000000)" stroke="#FFFFFF" strokeWidth="2">
                              <g id="Group-2-Copy-3" transform="translate(174.000000, 1387.000000)">
                                <g id="Group-5-Copy-3" transform="translate(1.000000, 316.000000)">
                                  <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <Link
                          to={others[0].node.path.alias}
                          className="blue-arrow-link white"
                          onClick={() => sendEvent({
                            ga: {
                              category: 'Engagement',
                              action: 'Click',
                              label: others[0].node.title.toLowerCase().replace(' ','_') + '_Click_Through'
                            }
                          })}
                        >
                          View Products
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"column-item" + ' ' + others[1].node.title.replace(/\s/g, '')} id={others[1].node.id}>
                <div className="new-look-badge" />
                <div
                  className="two-col-grid-item-wrapper"
                  style={{
                    backgroundImage: `url(${others[1].node.relationships.field_image.localFile.childImageSharp.sizes.src})`,
                  }}>
                  <div className="two-col-item-image" />
                  <div className="two-col-item-text">
                    <div className="two-col-text-title white-sans-title">
                      {others[1].node.title}
                      {(others[1].node.id === 'bb5ec40d-3e69-5466-9cca-efc4195fc4fc') ? <sup>®</sup> : null}
                      {(!soothingaloe) ? <> triple soft<sup>®</sup></> : null}
                    </div>
                    <div className="arrow-link">
                      <svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                          <g id="02-Products" transform="translate(-174.000000, -1708.000000)" stroke="#FFFFFF" strokeWidth="2">
                            <g id="Group-2-Copy-3" transform="translate(174.000000, 1387.000000)">
                              <g id="Group-5-Copy-3" transform="translate(1.000000, 316.000000)">
                                <polyline id="Shape-Copy-2" points="0 6 4 10 0 14"></polyline>
                              </g>
                            </g>
                          </g>
                        </g>
                        </svg>
                        <Link
                          to={others[1].node.path.alias}
                          className="blue-arrow-link white"
                          onClick={() => sendEvent({
                            ga: {
                              category: 'Engagement',
                              action: 'Click',
                              label: others[1].node.title.toLowerCase().replace(' ','_') + '_Click_Through'
                            }
                          })}
                        >
                          View Products
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </main>
        {/* <Offers bypassHide={tripleSoft ? true:false} /> */}
        <GeneralFooter />
        { tripleSoft ?
          <>
            <img height="1" width="1" style={{'position':'absolute', visibility:'hidden'}} alt="pixel" src="https://www.facebook.com/tr?id=1723838924437800&ev=PageView&noscript=1" />
            <img height="1" width="1" style={{'position':'absolute', visibility:'hidden'}} alt="pixel" src="https://data.adxcel-ec2.com/pixel/?ad_log=referer&action=lead&value={%22af_revenue%22:%22[value]%22,%22af_order_id%22:%22[order_id]%22}&pixid=3c28f711-7009-49e8-9fdd-b7601574e05d" border="0" />
            <script async src={"https://live.rezync.com/sync?c=16b6410431b6374e780104abb0443ca8&p=50f66ee559d16c976f4618ed65acf7dc&k=scotties-pixel-2787&zmpID=scotties&cache_buster=" + Date.now()} type="text/javascript"></script>
          </>
          :
          null
        }
      </Layout>
    )
  }
}

export default ProductTemplate;

export const query = graphql`
  query ProductTemplate($slug: String!) {
    nodeProduct(path: {alias: { eq: $slug } }) {
      id
      path {
        alias
      }
      title
      field_meta_tags
      field_has_multipacks_
      field_product_details {
        value
      }
      relationships {
        field_bazaarvoice_products {
          title
          body {
            value
          }
          field_bazaarvoice_id
          field_bazaarvoice_upc
          field_bazaarvoice_image
        }
        field_image_background_detail {
          localFile {
            childImageSharp {
              resolutions(width: 1600, quality: 85) {
                src
              }
            }
          }
        }
        field_product_variation {
          id
          relationships {
            field_box_image {
              localFile {
                childImageSharp {
                  resolutions(width: 800) {
                    src
                  }
                }
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width: 86) {
                    src
                  }
                }
              }
            }
            field_product_sizes {
              name
            }
          }
        }
        field_product_store {
          id
          title
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width: 143) {
                    src
                  }
                }
              }
            }
            field_state {
              name
            }
          }
        }
        field_paragraph_content {
          __typename,
          ... on paragraph__buy_online {
            id
            field_buy_online_copy {
              value
            }
            field_buy_online_link {
              uri
              title
            }
            field_buy_online_header {
              value
            }
          }
          ... on paragraph__shop_scotties {
            field_shop_scotties_header {
              value
            }
          }
          ... on paragraph__four_icon_grid {
            field_four_icon_grid_header
            field_four_icon_grid_subheader
            relationships {
              field_four_icon_grid_content {
                field_icon
                field_icon_grid_title
                field_icon_grid_details {
                  value
                }
              }
            }
          }
        }
      }
    }
    allNodeProduct(filter: {path: {alias: { nin: [$slug,"/products/ultra-plush"] } }}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth:380, quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeStore {
      edges {
        node {
          id
          title
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  resolutions(width: 143) {
                    src
                  }
                }
              }
            }
            field_state {
              name
            }
          }
        }
      }
    }
  }
`
