/* eslint-disable */
import React from 'react';

import bathroom from "./../layouts/images/Bathroom.svg";
import car from "./../layouts/images/Car.svg";
import kids from "./../layouts/images/Kids-Room.svg";
import living from "./../layouts/images/Living-Room.svg";

class FourIconGrid extends React.Component {
  render() {
    return (
      <div className="text-and-four-col-icon-grid" id="available">
        <div className="text-and-four-col-icon-grid-title">
          <div className="intro-bold blue-text">{this.props.data.field_four_icon_grid_subheader}</div>
          <div className="intro-light font-32">{this.props.data.field_four_icon_grid_header}</div>
        </div>

        {(this.props.data.relationships.field_four_icon_grid_content) ?
          <div className="four-col-icon-grid">
          {this.props.data.relationships.field_four_icon_grid_content.map(item => {
            return (
              <div className="four-col-icon-grid-item" key={item.field_icon}>
                <div className="four-col-grid-item-wrapper">
                  <div className="four-col-grid-item-image">
                    { (item.field_icon === 'Bathroom.svg') ? (<img src={bathroom} className="icon-image" alt="" />) : null }
                    { (item.field_icon === 'Car.svg') ? (<img src={car} className="icon-image" alt="" />) : null }
                    { (item.field_icon === 'Living-Room.svg') ? (<img src={living} className="icon-image" alt="" />) : null }
                    { (item.field_icon === 'Kids-Room.svg') ? (<img src={kids} className="icon-image" alt="" />) : null }
                  </div>
                  <div className="intro-bold dark-grey-text">{item.field_icon_grid_title}</div>
                  <div className="section-body-text col-body-text centered-text no-btm-margin" dangerouslySetInnerHTML={{__html: item.field_icon_grid_details.value }}></div>
                </div>
              </div>
            )
          })}
        </div>
        : null }
      </div>
    )
  }
}

export default FourIconGrid
